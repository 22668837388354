const hljs = require('highlight.js/lib/highlight');

// hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript'));
// hljs.registerLanguage('python', require('highlight.js/lib/languages/python'));
// hljs.registerLanguage('xml', require('highlight.js/lib/languages/xml'));
// hljs.registerLanguage('django', require('highlight.js/lib/languages/django'));
// hljs.registerLanguage('bash', require('highlight.js/lib/languages/bash'));
// hljs.registerLanguage('css', require('highlight.js/lib/languages/css'));
// hljs.registerLanguage('markdown', require('highlight.js/lib/languages/markdown'));
// hljs.registerLanguage('diff', require('highlight.js/lib/languages/diff'));
// hljs.registerLanguage('json', require('highlight.js/lib/languages/json'));
// hljs.registerLanguage('less', require('highlight.js/lib/languages/less'));
// hljs.registerLanguage('nginx', require('highlight.js/lib/languages/nginx'));
// hljs.registerLanguage('scss', require('highlight.js/lib/languages/scss'));
// hljs.registerLanguage('shell', require('highlight.js/lib/languages/shell'));
// hljs.registerLanguage('sql', require('highlight.js/lib/languages/sql'));

module.exports = hljs;
